import Dialog from '@marpla/ui/components/Dialog';
import { useCallback, useMemo, useState } from 'react';

export default function useDialog() {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const props = useMemo(
    () => ({
      open: isOpen,
      onClose: close,
    }),
    [isOpen, close],
  );

  return { open, close, props, Component: Dialog };
}
