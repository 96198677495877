import Typography from '@marpla/ui/components/Typography';
import Link from '@marpla/ui/components/Link';

export function ChangeTariffContent() {
  return (
    <>
      <Typography>
        Уважаемые пользователи!
        <br />
        <br />
        Спасибо, что выбираете сервис Марпла. Мы ценим ваше доверие и стремимся сделать наш сервис
        максимально полезным и эффективным.
        <br />
        <br />
        За последние три месяца мы внедрили множество важных функций, которые помогают вам продавать
        больше и тратить меньше времени на рутину. Ниже перечислены ключевые обновления, а впереди —
        ещё больше ценных инструментов, которые мы уже готовим для вас.
        <br />
        <br />
        Чтобы и дальше активно развивать сервис, внедрять новые технологии и обеспечивать его
        стабильную работу, с 1 апреля 2025 года мы обновляем условия тарификации. Это решение
        позволит сохранить высокое качество работы платформы и продолжить выпускать полезные
        обновления.
      </Typography>
      <br />
      <Typography variant="bodySemibold">Что нового появилось за последние месяцы:</Typography>
      <br />
      <Typography variant="h3">Модуль "SEO":</Typography>
      <ul>
        <li>
          Вкладка "Полки" (артикулы, в которых карточка отображается в блоке "Смотрите также", с
          позициями);
        </li>
        <li>
          Заказы в рублях и штуках по артикулам конкурентов во вкладках “Полки” и “Сравнение”;
        </li>
        <li>
          Сравнение карточек по складам, размерам, характеристикам, полкам, выручке, рейтингу,
          ценам;
        </li>
        <li>Поддержка словоформ в базе запросов;</li>
        <li>Новый функционал точечного и общего анализа карточки в "Оптимизаторе".</li>
      </ul>
      <Typography variant="h3">Модуль "Реклама":</Typography>
      <ul>
        <li>Папки для группировки артикулов на главной странице;</li>
        <li>Отображение общих фраз на 2-й и 3-й страницах;</li>
        <li>“Тренды” — динамика изменений фраз, полок на 3-й странице (страница РК);</li>
        <li>Заказы в рублях и штуках по артикулам в Полках;</li>
        <li>История изменений в рекламных кампаниях;</li>
        <li>Возможность добавления заметок.</li>
      </ul>
      <Typography variant="h3">Модуль "Продажи":</Typography>
      <ul>
        <li>Новый модуль "Продажи" с расчётом юнит-экономики.</li>
      </ul>
      <Typography>
        Мы стараемся делать сервис лучше каждый день, и верим, что предложенные изменения помогут
        нам и дальше развивать платформу в интересах всех пользователей. Благодарим вас за
        поддержку!
      </Typography>
      <br />
      <Typography variant="h3">Вот какие изменения произойдут.</Typography>
      <Typography variant="h3" shade={500}>
        Тариф Бесплатный. Бесплатно.
      </Typography>
      <ul>
        <li>Модуль SEO: 1 проект, 500 фраз</li>
        <li>Модуль Реклама: 1 артикул</li>
        <li>Модуль Продажи: 1 магазин</li>
        <li>Модуль АБ тесты: 2 теста в месяц</li>
      </ul>
      <Typography variant="h3" shade={500}>
        Тариф в Помощь. Бесплатно. (Могут использовать магазины с выручкой менее 500 тыс.руб. за 30
        дней)
      </Typography>
      <ul>
        <li>Модуль SEO: 3 проекта, 900 фраз</li>
        <li>Модуль Реклама: 1 магазин</li>
        <li>Модуль Продажи: 1 магазин</li>
        <li>Модуль АБ тесты: 3 теста в месяц</li>
      </ul>
      <Typography variant="h3" shade={500}>
        Тариф Базовый. 217 руб.день
      </Typography>
      <ul>
        <li>Модуль SEO: 5 проектов, 5 000 фраз</li>
        <li>Модуль Реклама: 1 магазин</li>
        <li>Модуль Продажи: 1 магазин</li>
        <li>
          Модуль АБ тесты: *безлимит на тесты. В течение двух месяцев будет реализована схема оплаты
          АБ тестов за пакеты по 5 штук (будет уведомление)
        </li>
        <li>
          Модуль АБ тесты также можно будет купить отдельно за 3000 руб в месяц. *Безлимит на тесты.
          В течение двух месяцев будет реализована схема оплаты АБ тестов за пакеты по 5 штук (будет
          уведомление)
        </li>
      </ul>
      <Typography variant="h3" shade={500}>
        Расширения пакетов
      </Typography>
      <ul>
        <li>
          Модуль SEO: <br />
          <strong>Фразы: </strong>
          <ul>
            <li>До 8K фраз +20 ₽ / день</li>
            <li>До 10K фраз +80 ₽ / день</li>
            <li>До 15K фраз +120 ₽ / день</li>
            <li>До 20K фраз +220 ₽ / день</li>
            <li>До 30K фраз +400 ₽ / день</li>
            <li>До 50K фраз +700 ₽ / день</li>
            <li>Безлимит +1466 ₽ / день</li>
          </ul>
          <strong>Проекты:</strong>
          <ul>
            <li>6-20 шт. +66 ₽ / день за каждые 5 шт</li>
            <li>21-40 шт. +33 ₽ /день за каждые 5 шт.</li>
            <li>41-100 шт. +17 ₽ / день за каждые 5 шт.</li>
            <li>100+ шт. +8 ₽ / день за каждые 5 шт.</li>
          </ul>
        </li>
        <li>Модуль Реклама: +1 магазин 50 руб. день</li>
        <li>Модуль Продажи: +1 магазин 50 руб. день</li>
      </ul>
      <Typography>
        По всем вопросам вы можете обратиться к нам в{' '}
        <Link to={'https://t.me/marpla_help_bot'} blank>
          поддержку
        </Link>
        . <br />
        <br />
        С уважением,
        <br />
        Команда Марпла
      </Typography>
    </>
  );
}
