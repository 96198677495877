// import { BM_MODE } from '@/env';

import { rtkQueryApi } from './rtk-query-api';

type ConfigRequest = string[];
type ConfigResponse = Record<string, string>;

export const otherApi = rtkQueryApi
  .enhanceEndpoints({ addTagTypes: ['UserFinance'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMoscowDateTime: builder.query<{ datetime: string }, any>({
        query: () => 'seo/get-moscow-date-time',
      }),
      getBmAccessToken: builder.query<any, any>({
        query: () => ({
          url: '/user/get-bm-token',
          method: 'post',
          body: {
            env: 'prod',
          },
        }),
      }),
      getConfig: builder.query<ConfigResponse, ConfigRequest>({
        query: (names) => ({
          url: '/user/get-config',
          method: 'post',
          body: {
            names,
          },
        }),
      }),
    }),
  });

export const {
  useLazyGetMoscowDateTimeQuery,
  useGetMoscowDateTimeQuery,
  useLazyGetBmAccessTokenQuery,
  useGetConfigQuery,
} = otherApi;
