import { useState, useEffect } from 'react';
import useDialog from '@/hooks/useDialog';
import InfoBox from '@marpla/ui/components/InfoBox';
import Typography from '@marpla/ui/components/Typography';
import Link from '@marpla/ui/components/Link';
import { Box } from '@mui/material';

import { type ReactNode } from 'react';

interface NotificationInfoProps {
  id: string;
  title: string;
  dialogContent: ReactNode;
}

const NotificationInfo = ({ id, title, dialogContent }: NotificationInfoProps) => {
  const [isCloseInfo, setIsCloseInfo] = useState(false);

  const {
    open: openDialog,
    close: closeDialog,
    Component: Dialog,
    props: dialogProps,
  } = useDialog();

  useEffect(() => {
    const isClosed = localStorage.getItem(`marpla_info_${id}`) === 'true';
    setIsCloseInfo(isClosed);
  }, [id]);

  const closeHandler = () => {
    localStorage.setItem(`marpla_info_${id}`, 'true');
    setIsCloseInfo(true);
    closeDialog();
  };

  return (
    <>
      <Dialog {...dialogProps} title={title}>
        {dialogContent}
      </Dialog>
      {!isCloseInfo && (
        <Box mt={2}>
          <InfoBox
            type="warning"
            onClose={closeHandler}
            text={
              <>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  <Typography variant="bodyRegular">{title}</Typography>
                  <Link to={''} onClick={openDialog}>
                    Подробнее
                  </Link>
                </Box>
              </>
            }
          />
        </Box>
      )}
    </>
  );
};

export default NotificationInfo;
