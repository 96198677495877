import { getMoscowDate } from '@/pages/report/utils/get-moscow-date.function';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import groupBy from 'lodash/groupBy';

export type ProductCarouselAllListType = {
  anchorArticle: number;
  timestamp: string;
  parseMethod: number;
  campaignWbId: number | null;
  position: number;
  boostPosition: number | null;
  cpm: number | null;
}[];

export interface ProductCarouselType {
  dailystats: ProductCarouselDailystatType[];
  anchorArticle: number;
}

export interface ProductCarouselDailystatType {
  anchorArticle: number;
  article: number;
  timestamp: string;
  date: string;
  parseMethod: number;
  campaignWbId: number;
  position: number;
  boostPosition: number;
  cpm: number;
}

export interface UnknownProductOut {
  article: number;
  imageThumbUrl?: string;
  imageUrl?: string;
  name: string;
  brandName: string;
  subjectWbId?: number;
  kindWbId?: number;
  accountWbId?: number;
  accountTrademark?: string;
  price: number;
  sppPrice: number;
  rating: number;
  feedbackCount: number;
}

export const productCarouselApi = createApi({
  reducerPath: 'productCarouselApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://parserapi.k8s.marpla.ru/',
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('bmT') || window.sessionStorage.getItem('bmT');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProductCarousels: builder.query<
      {
        productCarouselByArticle: Map<number, ProductCarouselType>;
      },
      { articles: number[] | string[] }
    >({
      query: ({ articles }) =>
        `/product_carousel/product_carousels/all?${articles.map((a) => `articles=${a}`).join('&')}&campaignWbId=1&group=true`,

      transformResponse: (_result: ProductCarouselAllListType, _, queryArgs) => {
        const result = _result[0];
        const article = queryArgs.articles[0];
        const productCarouselByArticle = new Map<number, ProductCarouselType>();
        const groupedDailystatRows = groupBy(result, ([, productArticle]) => productArticle);

        const groupedCarouselRows = Object.entries(
          groupBy(result, ([, productArticle]) => productArticle),
        );

        for (const [productArticle] of groupedCarouselRows) {
          const dailystatRows = groupedDailystatRows[+productArticle];

          const dailystats: ProductCarouselDailystatType[] = dailystatRows
            ? dailystatRows.map(
                ([anchorArticle, article, timestamp, parseMethod, campaignWbId, position, boostPosition, cpm]) => ({
                  anchorArticle,
                  article,
                  timestamp,
                  date: getMoscowDate(new Date(timestamp)),
                  parseMethod,
                  campaignWbId: campaignWbId || 0,
                  position,
                  boostPosition: boostPosition || 0,
                  cpm: cpm || 0,
                }),
              )
            : [];

          const product = {
            dailystats,
            anchorArticle: +article,
          };

          if (!productCarouselByArticle.has(+productArticle)) {
            productCarouselByArticle.set(+productArticle, product);
          }
        }

        return {
          productCarouselByArticle,
        };
      },
    }),
    getFboOrders: builder.query<any, {anchorArticles:number[]}>({
      query: (payload) => ({
        url: 'https://analyticsapi.k8s.marpla.ru/carousel/carousels',
        method: 'POST',
        body: payload,
      }),
    }),
    getProductInfo: builder.query<UnknownProductOut[], { articles: number[] }>({
      query: ({ articles }) => ({
        url: 'https://bmapi.k8s.marpla.ru/product/products/unknowns',
        params: articles.reduce((params, article) => {
          params.append('articles', article.toString());
          return params;
        }, new URLSearchParams()),
      }),
    }),
    updateDailyStat: builder.mutation<
      ProductCarouselDailystatType[],
      {
        accountArticles: { accountWbId: number; article: number }[];
        anchorArticle: number;
        depth: number;
      }
    >({
      query: (payload) => ({
        url: '/product_carousel/product_carousels/dailystat',
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyGetProductCarouselsQuery,
  useLazyGetProductInfoQuery,
  useLazyGetFboOrdersQuery,
  useUpdateDailyStatMutation,
} = productCarouselApi;
